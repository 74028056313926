<template>
  <div class="col-lg-5 mb-3">
    <div class="reservation_form" >
      <form name="contact" id="homepage" :class="`${landingInfo.utm_source} - ${destinationName.he}`" @submit.prevent>
        <p>
        לקבלת שיחה חוזרת מנציג מכירות שלנו,<br>
אנא מלא את הטופס ונחזור אליך במהירות האפשרית עם מיגוון ענק וכל המידע על מבצעים ודילים במלונות בארץ
        </p>
        <div class="reservation_formbox clearfix" >
          <div class="form-group" >
              <label for="">מספר הטלפון שלך</label>
            <div class="input-group" >
              <div class="input-group-prepend" >
                <div class="input-group-text"><i class="fas fa-phone"></i></div>
              </div>
              <input type="number" class="form-control" required name="phone" v-model="phone">
            </div>
          </div>
          <div class="form-group" >
            <label for=""> במה נוכל לעזור לך?</label>
            <div class="input-group" >
              <div class="input-group-prepend" >
                <div class="input-group-text"><i class="fas fa-question-circle"></i></div>
              </div>
              <select name="subject" class="form-control" v-model="subject">
                <option v-for="(dest, inx) in destinationList" :key="inx">
                  {{`מעוניין בנופש ${dest.name}`}}
                </option>
              </select>
            </div>
          </div>
          <div v-if="sending" style="position: absolute;"><img src="/assets/img/loud.gif" border="0" width="50" height="50" alt=""> אנא המתן</div>
          <div v-if="success" style="position: absolute;font-size:1.5rem;"><b> קיבלנו את הודעתך <i class="fas fa-check"></i></b></div>
          <button id="button" type="submit" class="btn btn-primary" :disabled="sending" @click="onSubmit" :class="`form - ${landingInfo.utm_source} - ${destinationName.he}`">שלח אלינו</button>
        </div>
      </form>
        <p class="contact-info">
          <a id="phone" :class="`form - ${landingInfo.utm_source} - ${destinationName}`" :href="`tel:${landingInfo.phone}`" >
            <img src="/assets/img/phone1.png" border="0" width="70px" height="70px" alt="">
            <span v-if="device=='desktop'">{{landingInfo.phone}}</span>
          </a>
        </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LandingContact',
  props: {
    dealTypeCode: String,
  },
  components: {
  },
  data() {
    return {
      sending: false,
      success: false,
      phone: '',
      subject: '',
    };
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      landingInfo: 'GET_LANDING_INFO',
      landingDealData: 'GET_LANDING_DEAL_DATA',
      destinationList: 'GET_CAMINGO_DESTINATION',
    }),
    destinationName() {
      const { destinationList } = this;
      if (!destinationList) return '';

      const destName = destinationList.find((item) => item.code === this.dealTypeCode).name;
      this.setSubject(destName);
      return destName;
    },
  },
  watch: {
    phone() {
      this.success = false;
    },
    subject() {
      this.success = false;
    },
  },
  mounted() {
  },
  methods: {
    async onSubmit() {
      // const body = new FormData();
      // body.append('phone', this.phone);
      // body.append('subject', this.subject);

      this.sending = true;
      let origin = -1;
      if (this.landingInfo.utm_source.toLowerCase() === 'google') origin = 1;
      if (this.landingInfo.utm_source.toLowerCase() === 'facebook') origin = 0;

      await this.$store.dispatch('SEND_CONTACT_FORM', {
        phoneNumber: this.phone,
        interestedIn: this.subject,
        destinedFor: this.landingInfo.destination,
        origin,
      });
      this.sending = false;

      this.success = true;
    },
    setSubject(dest) {
      this.subject = `מעוניין בנופש ${dest}`;
    },
  },
};
</script>

<style scoped>
  .reservation_form {
    background-color: #fff;
    width: 100%;
    min-height: 500px;
    box-shadow: 0px 0px 2px 9px rgb(0 97 171 / 15%);
    box-sizing: border-box;
    padding: 30px;
    display: grid;
    flex-direction: column;
  }
  .reservation_form form{
    margin-top: auto;
  }
  .reservation_form p {
    font-size: 15px;
    font-weight: bold;
  }
  .reservation_formbox {
    box-sizing: border-box;
    padding: 24px 30px;
    background-color: rgb(0 97 171 / 5%);
    border: 1px solid rgb(0 0 0 / 5%);
  }
  .reservation_formbox label {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    color: #737373;
  }
  .reservation_formbox button.btn {
    font-size: 14px;
    text-transform: uppercase;
    float: right;
    border-radius: 0px;
  }
  .reservation_form .contact-info {
    text-align: center;
    font-size: 1.5em;
    margin-top: 30px;
  }
  .reservation_form .contact-info span{
    color: white;
    background-color: #0061ab;
    padding: 5px;
  }
  .input-group-prepend .input-group-text{
    background-color: #0061ab;
    color: white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: none;
  }
  .reservation_formbox input, .reservation_formbox select {
    border-radius: 0;
    border-right: none;
  }
@media (max-width: 768px){
  .reservation_form {
    padding: 30px 10px;
    margin: auto;
    width: calc(100% - 15px);
  }
  .reservation_formbox {
    padding: 20px 10px;
  }
}
</style>
